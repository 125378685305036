import { Box, Typography, Grid, Paper, styled, Button, Card, Container, Accordion } from '@mui/material'
import Cards from '../pages/landingPage/CardContent/Cards'
import ShakeOnIt from '../../assets/icons/ShakeOnIt.png';
import React from 'react'
import Accordions from '../Accordions';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));

const Savings = () => {
  return (
    <Box sx={{bgcolor:'#3a3c3d'}}>   
      <Container>     
          <Typography variant='h2' sx={{color: 'white', pt: '5%', fontSize: '35px', fontWeight:'600', letterSpacing: '.1rem'}}>Personal Savings Options</Typography>     
          <Box sx={{display: {xs: 'block', lg:'flex'}, mx: {xs: '0', sm: '2%', md:'-10%'}}}>            
            <Cards imageSource={ShakeOnIt} title = "GoldenTime Ease In Savings" description = "The most popular savings account." 
                link = "#" linkTitle ="Open Now" bulletPoints={["Comes with GoldenTime Fraud Protection", "8.5% APY"]} extraInformation="$4.99 per month" />
            <Cards imageSource={ShakeOnIt} title = "GoldenTime Wild West Savings" description = "The first account to feel like gold will rain down into your account." 
                link = "#" linkTitle ="Open Now" bulletPoints={["Comes with GoldenTime Fraud Protection", "Simple 24 hour transfer from savings to checkings","14.5% APY"]} extraInformation="$24.99 per month" />
            <Cards imageSource={ShakeOnIt} title = "GoldenTime Golden Egg Savings" description = "The most popular savings account." 
                link = "#" linkTitle ="Open Now" bulletPoints={["Comes with GoldenTime Fraud Protection", "Instant transfers from savings to checkings.", "20% APY"]} extraInformation="$54.99 per month" />
          </Box>  
          <Typography variant='h2' sx={{color: 'white', pt: '5%', fontSize: '35px', fontWeight:'600', letterSpacing: '.1rem'}}>GoldenTime Savings Options</Typography>
          <Box sx={{display: {xs: 'block', lg:'flex'}, mx: {xs: '0', sm: '2%', md:'-10%'}}}>
            <Cards imageSource={ShakeOnIt} title = "GoldenTime Golden Goose Savings" description = "Have a personalized wealth machine." 
                link = "#" linkTitle ="Open Now" bulletPoints={["Comes with all personal account options.", "Investment options available.", "25% APY"]} extraInformation="$102.99 per month" />
            <Cards imageSource={ShakeOnIt} title = "GoldenTime OnFire Savings" description = "It's almost like a personal printing press." 
                link = "#" linkTitle ="Open Now" bulletPoints={["Comes with all previous personal and GoldenTime options", "Uses AI to suggest best course of action.","35% APY"]} extraInformation="$185.99 per month" />
            <Cards imageSource={ShakeOnIt} title = "GoldenTime PureGold Savings" description = "Epic account with the most diverse options and amazing, never before seen, returns." 
                link = "#" linkTitle ="Open Now" bulletPoints={["Comes with all previous personal and GoldenTime options", "Can utilize our AI to work for you.", "50% APY"]} extraInformation="$399.99 per month" />
          </Box>          
      </Container>
      <Box sx={{bgcolor: '#fff8e1'}}>
        <Container sx={{pb:5}}>
            <Typography variant='h4' sx={{fontSize:'28px', pt: 8, pb: 3, fontWeight: 600}}>WHY CHOOSE GOLDENTIME?</Typography>
              <Grid item xs={12} sx={{display: 'flex',}}>
                <Item sx={{px:5, bgcolor:'#fff8e1', color: 'black', mx: 'auto' }}>
                  <Typography variant='h5' sx={{fontSize: '22px', }}>
                    Beat inflation with amazing APY!
                  </Typography>
                </Item>
                <Item sx={{px:5, bgcolor:'#fff8e1', color: 'black', mx: 'auto'}}>
                  <Typography >
                    24/7 Mobile AI support
                  </Typography>
                </Item>
              </Grid>
            </Container>
        </Box>
        <Box sx={{textAlign: 'left', pt: 5, pb: 5}}>                      
            <Accordions FAQQuestion='How do I open an account at Golden Time?' FAQAnswer='Sorry, you cannot. This is not a real bank. Wish it was.' /> 
            <Accordions FAQQuestion='Which GoldenTime account is right for me?' FAQAnswer='... If only this was a real bank.' />
            <Accordions FAQQuestion='What type of account should I get if I&apos;m looking for interest?' FAQAnswer='No answer.' />                
        </Box>
    </Box> 
  )
}

export default Savings
