import { Box, Button, Card, TextField } from "@mui/material"
import BankingApi from "../../../api/BankingApi";
import { ChangeEvent, FormEvent, useState } from "react";
import axios from 'axios';
import { LoginRequest } from "../../../api/Models/LoginRequest";


export function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }
  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }


  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
  
      const loginRequest = {
        email: email,
        password: password
      };
  
      const response = await BankingApi.LoginAsync(loginRequest);
    }
  
    return (     
      <>
        { isLoggedIn ? (
            <section>
                <h1>You are logged in!</h1>
            </section>
        ) : ( 
        <Box component="form" onSubmit={onSubmit} >            
            <TextField 
              required
              fullWidth
              id="email"
              name="email" 
              value={email}
              label="email"
              variant='filled'
              onChange={onEmailChange} 
              sx={{ display:{xs: 'grid', md: 'grid'}, bgcolor: '#ffe1bf', color: 'black', borderRadius: '10px', mt:'15px', mx: '45px', width: '250px' }} />        
            <TextField  
              type="password" 
              value={password} 
              name="password" 
              required  
              id="password" 
              label="Password" 
              variant='filled'
              onChange={onPasswordChange}
              sx={{ display:{xs: 'grid', md: 'grid'}, bgcolor: '#ffe1bf', color: 'black', borderRadius: '10px', mt:'15px', mx: '45px', width: '250px'}} /> 
            <Button type="submit" size="large" color="primary" variant='contained' sx={{ color:'black', bgcolor:'gold', display:'block', mt:'15px', mb: 0, opacity: '100%', mx:'auto', 
            borderRadius: '10px', transition: 'transform .4s', '&:hover': {bgcolor: '#4d4843', color: 'gold', transform: 'Scale(1.3)'}}}
            >
                Sign In
            </Button>                     
        </Box>
          )}
        </> 
  )
}
