import { Box, Button, TextField } from "@mui/material"
import { FormWrapper } from "./FormWrapper"
import { ChangeEvent, FormEvent, useState, MouseEvent } from "react"
import BankingApi from "../../../api/BankingApi"

// const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;



export function SignupForm( ) {    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPressed, setIsPressed] = useState(false);

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }
    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }
    
    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        setIsPressed(true);
      };

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        
        const signupRequest = {
            name: name,
            email: email,
            password: password
        };

        console.log(signupRequest);
        await BankingApi.SignupAsync(signupRequest);     
    }

    
    
    return (
        <Box component='form' id='formId' onSubmit={onSubmit}>
            <FormWrapper title="Sign Up">            
                <TextField 
                required 
                value={name} 
                name="name" 
                id="name" 
                label="Full Name" 
                variant='filled' 
                onChange={onNameChange}
                sx={{ border: '3px solid darkolivegreen', bgcolor: '#ffe1bf', color: 'black', borderRadius: '5px', mx: '15px'}} />
                <TextField 
                type="email" 
                value={email} 
                name="email" 
                required 
                id="email" 
                label="Email" 
                variant='filled' 
                onChange={onEmailChange}
                sx={{ border: '3px solid darkolivegreen', bgcolor: '#ffe1bf', 
                color: 'black', borderRadius: '5px', mx: '15px'}} />             
                <TextField 
                type="password" 
                value={password} 
                name="password" 
                required  
                id="password" 
                label="Password" 
                variant='filled' 
                onChange={onPasswordChange}
                sx={{ border: '3px solid darkolivegreen', bgcolor: '#ffe1bf', color: 'black', 
                borderRadius: '5px', mx: '15px'}} />  
                <Button onClick={handleMouseEvent} 
                type="submit" 
                size="large" 
                color="primary" 
                variant='contained'  
                sx={{ color:'black', bgcolor:'gold', display:'block', mt:'15px', mb: 0, opacity: '100%', mx:'auto', 
                borderRadius: '10px', transition: 'transform .4s', '&:hover': {bgcolor: '#4d4843', color: 'gold', transform: 'Scale(1.3)'}}}
                >
                    Sign Up
                </Button>                   
            </FormWrapper>
            <Box sx={{color:'black', bgcolor:'#0dff00', display:'block', mt:'10%', mb: 0, opacity: '100%', mx:'auto', borderRadius: '10px'}}>
                { isPressed && <h1 style={{ fontSize: '20px', padding: '15px', lineHeight: '20px' }}> Thank you for signing up <em>{name}</em>. You may click anywhere to exit this page.</h1>}
            </Box>
         </Box>
    )
}