import React from 'react'
import { Box, Button, Card, CardContent, Typography, CssBaseline, CardMedia } from "@mui/material"

function LargeCard ( { imageSource = "", title = "", description = "", link = "", linkTitle ="", bulletPoints = [""], serviceFee = "Monthly Service Fee", extraInformation = "" } ) {

  return (
    <Box sx={{opacity: '0.8', mx:'auto' }} >
        <CssBaseline />                
        <Card sx={{ display: 'grid', width: 425, height:565, p: '25px', mx:'auto', mt: '5%', mb: '5%', borderRadius: '15px', bgcolor: 'black', color: 'white' }}>
            <CardMedia>
                <img src={imageSource} alt={title} style={{maxHeight:'85px'}} />
            </CardMedia>     
                <Typography gutterBottom variant="h3" sx={{fontSize: '28px'}}>
                        {title}
                    </Typography>                
                <CardContent sx={{alignSelf:'flex-start'}}>                    
                    <Typography variant="h5" sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'left' }}>
                        {description}
                    </Typography>
                    <Typography variant='body2' sx={{pt: 1}}>                
                        {bulletPoints.map((bullet) => (
                        <Typography key={bullet} sx={{ pt:1, fontSize: '15px', textAlign: 'left', overflow: 'hidden', bgcolor: 'none' }}>
                        <li style={{}}>                   
                            {bullet}                     
                        </li>                   
                        </Typography>
                        ))}
                    </Typography>
                </CardContent>
                    <Box sx={{alignSelf:'flex-end'}}>
                        <Typography sx={{ fontSize: '14px', textAlign: 'left', overflow: 'hidden', bgcolor: 'none', pl:2 }}>
                            {serviceFee}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', textAlign: 'left', overflow: 'hidden', bgcolor: 'none', pl:2, pb:2 }}>
                            {extraInformation}
                        </Typography>
                        <Button href={link} size="medium" variant='contained' sx={{ bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                        '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                            {linkTitle} &#8594;
                        </Button>
                    </Box>                    
        </Card>
        </Box>
  )
}

export default LargeCard
