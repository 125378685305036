import axios from "axios";
import { SignupRequest } from "./Models/SignupRequest";
import { LoginRequest } from "./Models/LoginRequest";

const instance = axios.create({
    baseURL: 'https://localhost:7126/api/',
    timeout: 10000,
    headers: {'Access-Control-Allow-Origin': 'http://localhost:3001'}
});

const SetHeaders = () => {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:3001'
    axios.defaults.withCredentials = true
}

async function SignupAsync(params: SignupRequest) {
    SetHeaders();

    instance.post('login', params)
        .then(function (response) {
        console.log(response);
        })
        .catch(function (error) {
        console.log(error);
        });
}

async function LoginAsync(params: LoginRequest) {
    SetHeaders();

    instance.put('login', params)
        .then(function (response) {
        console.log(response);
        })
        .catch(function (error) {
        console.log(error);
        });
        
}

export default {
    SignupAsync,
    LoginAsync
}
