import { Box, Button, Card, CardMedia, CardContent, CardActions, CssBaseline, Typography, TextField, Popover, Modal } from '@mui/material'
import LandingImage from '../../../assets/images/landing-image.png';
import InfoCardContent from './CardContent/InfoCardContent';
import SignInContent from './CardContent/SignInContent';
import { useState } from 'react';

import '../../pages/styles.css'
import { SignupForm } from '../../persistentElements/multiForm/signupForm';

const HeroSection = () => {
    
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  return (
    <div style={{backgroundColor:'black'}}>
        <Box sx={{display: {xs:'none', sm:'none', md:'flex'}, backgroundColor:'black'}}>
            <img src={LandingImage} alt="Sunset Image" className='landing-image' />
        </Box>
        <Box sx={{display: {xs:'flex', md:'none'}, opacity:'0',}}>
            <img src={LandingImage} alt="Sunset Image" className='landing-image' />
        </Box>
        <Box>
            {/* Info Card on medium and larger screens */}
            <Card sx={{ display: {xs:'none', sm:'none', md:'block'}, maxWidth: 345, mt: '-50%', ml: '20%', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>    
                <InfoCardContent />
            </Card>

            {/* Info Card on small screens */}
            <Card sx={{ display: {xs:'none', sm:'block', md:'none'}, maxWidth: 345, mt: '15%', mx: 'auto', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>                 
                <InfoCardContent />
            </Card>

            {/* Info Card on extra small screens */}
            <Card sx={{ display: {xs:'block', sm:'none', md:'none'}, maxWidth: 345, mt: '15%', mx: 'auto', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>                 
                <InfoCardContent />
            </Card>

            
            {/* Sign In Card for extra large screens */}
            <Card sx={{ display:{xs:'none', sm:'none', md:'none', lg:'none', xl:'block'}, position:'absolute', maxWidth: 850 , ml:'45%', maxHeight: 475, mt: '-650px', color: 'white', bgcolor: 'black', 
            opacity: '78%', p: '2%', borderRadius: '15px' }}>                
                <SignInContent />                 
            </Card> 

            {/* Sign In Card for large screens */}
            <Card sx={{ display:{xs:'none', sm:'none', md:'none', lg:'block', xl:'none'}, position:'absolute',
                maxWidth: 550 , ml:'53%', maxHeight: 475, mt: '-610px', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>
                <SignInContent />
            </Card>

            {/* Sign In Card for medium screens  */}
            <Card sx={{ display:{xs:'none', sm:'none', md:'block', lg:'none', xl:'none'}, position:'absolute',
                maxWidth: 750, ml:'58.5%', maxHeight: 475, mt: '-610px', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>
                <SignInContent />
            </Card>

            {/* Sign In Card for small screens  */}
            <Card sx={{ display:{xs:'none', sm:'block', md:'none', lg:'none', xl:'none'}, position:'absolute', mx: 'auto',
                maxWidth: '80vw', maxHeight: 475, mt: '-925px', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>
                <SignInContent />
            </Card>

            {/* Sign In Card for extra small screens */}
            <Card sx={{ display:{xs:'block', sm:'none', md:'none', lg:'none', xl:'none'},
                maxWidth: '80vw', mx:'auto', maxHeight: 475, mt: '-845px', color: 'white', bgcolor: 'black', opacity: '78%', p: '2%', borderRadius: '15px' }}>
               <SignInContent />
            </Card> 
        </Box>  
    </div>
  )
}

export default HeroSection