
import "./App.css"
import { Route, Routes } from "react-router"; 
import LandingPage from "./components/pages/landingPage/LandingPage"
import Navbar from "./components/persistentElements/Navbar"
import Checking from "./components/pages/Checking";
import Education from "./components/pages/Education";
import Invest from "./components/pages/Invest";
import Savings from "./components/pages/Savings";
import Loans from "./components/pages/Loans";
import Automotive from "./components/pages/Automotive";
import CreditCards from "./components/pages/CreditCards";
import Footer from "./components/persistentElements/Footer";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <div className="App pageBg"> 
      <Navbar />
      <ScrollToTop />
      <Routes>        
        <Route path="/" element={<LandingPage />} />
        <Route path="Checking" element={<Checking />} />
        <Route path="Education" element={<Education />} />
        <Route path="Invest" element={<Invest />} />
        <Route path="Loans" element={<Loans />} />
        <Route path="Savings" element={<Savings />} />
        <Route path="Credit Cards" element={<CreditCards />} />
        <Route path="Automotive" element={<Automotive />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
