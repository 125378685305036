import React from 'react'
import { Container,CssBaseline } from '@mui/material';
import ShakeOnIt from '../../../assets/icons/ShakeOnIt.png';
import NoImpact from '../../../assets/icons/No-Impact.png'
import SaveMoney from "../../../assets/icons/Save-Money.png"

import '../../pages/styles.css'
import { MiniCards } from './CardContent/MiniCards';

const WhyUs = () => {
  return (
    <div className='why-us-section'>
            <CssBaseline />            
                <Container sx={{display: {xs:'block', md:'flex'}, mt: {xs: '115%', sm: '0'}, mx:'auto'}}>
                    <MiniCards imageSource={NoImpact} title="No Impact, No Worries" linkTitle='See if you qualify'
                    description="Check if you're pre-approved for offers with no impact to your credit score." link="#" />
                    <MiniCards imageSource={SaveMoney} title="Saving starts here" linkTitle='Compare accounts'
                    description="Keep your money growing with the best savings rate in America. We guarantee it!" link="/Savings" />                
                    <MiniCards imageSource={ShakeOnIt} title="Easier purchasing" linkTitle='See the magic'
                    description="Why wait when you can get what you want now! Interest rates as low as 5% on loans and credit cards!" link="/Credit Cards" />                
                </Container>
    </div>
  )
}

export default WhyUs