import * as React from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, createTheme, ThemeProvider, InputLabel, FormLabel } from '@mui/material';
import { Desire, desireInfo, purposeInfo, cardInfo } from '../../../DesireInfo';
import "../styles.css"

const theme = createTheme({
  components: {       
    MuiSelect: {      
      styleOverrides: {
        icon: ({ theme }) =>
          theme.unstable_sx({
            color: 'white',
            fill: 'white',
            fontSize: '35px'
          }),    
        root: ({ theme }) =>
          theme.unstable_sx({
            color: 'white',
            fill: 'white',
          }),   
      },          
    },      
  }
});

export function DesireSelect( {title = ""} ) {
      const [want, setWant] = React.useState('');
    
      const handleChangeWant = (event: SelectChangeEvent) => {
        setWant(event.target.value);
      };

      return (
        <ThemeProvider theme={theme}>
          <Box sx={{ color: 'white', mt:{xs: '6%', sm:'10%', xl:'13%'}, display: {xs: 'block', sm: 'inline-flex'} }}>
            <h1>{title}</h1>            
            <FormControl variant="standard" sx={{ mt:2, mx: 5, minWidth: 320 }}>                
              <Select
                value={want}   
                onChange={handleChangeWant}
                sx={{ fontSize:'30px' }}
              >            
                  {
                    desireInfo.map((desire:Desire) => 
                    <MenuItem  
                    key={desire.id}
                    value={desire.id}
                    >
                      {desire.description}
                    </MenuItem>
                  )}   
              </Select>
            </FormControl> 
          </Box>            
        </ThemeProvider>
      );
    }


  export function PurposeSelect() {
      const [purpose, setPurpose] = React.useState('');
    
      const handleChangePurpose = (event: SelectChangeEvent) => {
        setPurpose(event.target.value);        
      }; 

      return (
        <ThemeProvider theme={theme}>
          <Box sx={{display: 'block', mt: {xs: '2%', sm:'0'}}}>
            <Box sx={{ color: 'white', mt:{xs: '1%', sm:'1%', xl:'2%'}, mb: '3%', display: {xs: 'block', sm: 'inline-flex'}}}>
              <h1>So that I can</h1>
              <FormControl variant="standard" sx={{ mt:2, mx: 5, minWidth: 320 }}>            
                <Select
                  value={purpose}   
                  onChange={handleChangePurpose}
                  sx={{fontSize:'30px'}}
                >                       
                    {
                      purposeInfo.map((purpose:Desire) => 
                      <MenuItem  
                      key={purpose.id}
                      value={purpose.id}
                      > 
                        {purpose.description}
                      </MenuItem>
                    )}   
                </Select>
              </FormControl>     
            </Box>
          </Box>
        </ThemeProvider>
      );
    }