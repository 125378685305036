import { useState } from 'react';
import { Box, Button, CardContent, Modal, TextField } from '@mui/material'
import { SignupForm } from '../../../persistentElements/multiForm/signupForm';
import { LoginForm } from '../../../persistentElements/multiForm/loginForm';

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const SignInContent = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <Box >       
        <CardContent>
                <LoginForm />             
            <Box sx={{ display: 'block', mt: '25px' }}>
                <Button onClick={handleOpen} size="small" sx={{ color:'white', bgcolor:'none', display:'block', mt:'25px', mb: 0, opacity: '100%', mx:'auto', 
                transition: 'transform .4s', '&:hover': {color: 'gold', transform: 'Scale(1.3)'}}}>
                    Don't have an account?
                </Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        >
                        <Box sx={{   position: 'absolute' as 'absolute',
                        width: '600px',
                        height: '600px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: '#c7c1a9',
                        border: '5px solid #000',
                        boxShadow: 24,
                        p: 4, }}>                                    
                            <SignupForm />
                        </Box>
                    </Modal> 
            </Box>
        </CardContent>   
    </Box>
  )
}

export default SignInContent
