import * as React from 'react';
import { Box, IconButton, Typography, Container, Avatar, Button, AppBar, Toolbar, Menu, Tooltip, MenuItem } from "@mui/material";
import { Link } from "react-router-dom"
import HamburgerIcon from '../../assets/icons/Hamburger-Icon.png'
import Logo from '../../assets/icons/logo.png';
import LogoSmall from '../../assets/icons/logo-small.png';
import '../pages/styles.css'


const pages = [ 'Checking', 'Savings', 'Credit Cards', 'Loans', 'Automotive', 'Invest', 'Education' ];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const login = [ 'Username', 'Password' ];


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [loggedin, setLoggedin] = React.useState(false);
  if (loggedin) {
    {settings.map((setting) => (
      <MenuItem key={setting} onClick={handleCloseUserMenu}>
        <Typography textAlign="center">{setting}</Typography>
      </MenuItem>
    ))}
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" elevation={5} sx={{pt: 5, pb: 3, bgcolor: '#202322', '&::-webkit-scrollbar': {display: 'none'}}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>         
        
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >           
            <Link to={`/`}>
              
              <img src={Logo} alt="Logo" />
            </Link>  
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              
            >
              <Avatar alt="Hamburger Icon" src={HamburgerIcon} sx={{transition: 'transform .3s', '&:hover': {transform: 'Scale(1.3)'} }} />             
            </IconButton>
            <Box sx={{pt: 5}} />
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ root: {"& .MuiPaper-root": {overflowY: 'none'}, '&.MuiPopover-root': {overflowY: 'none'}} , overflowY:'hidden', bgcolor: 'black', width: '225px', 
              opacity: '85%', height: '100vh', mt: '208px' , transition: 'transform .3s', 
                display: { xs: 'block', md: 'none' }
              }}          
              MenuListProps={{
                disablePadding: true,  
                sx: { overflow: 'hidden' },                               
              }}
              style={{overflow: 'hidden'}}
              disablePortal
            >
              
              {pages.map((page) => (
                <MenuItem disableGutters key={page} onClick={handleCloseNavMenu} sx={{overflowY: 'hidden', bgcolor: 'black', p:4, '&:hover': { bgcolor: 'black', transform: 'Scale(1.5)'} }}>
                  <Typography sx={{ml: 2}}>
                    <Link to={`/${page}`} className='navLinks'>                      
                      {page}  
                    </Link>                     
                  </Typography>
                   
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
            <Link to={`/`}>
              <img src={LogoSmall} alt="Logo" />
            </Link>
          </Typography>
          

          <Box sx={{ flexGrow: 1 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, transition: 'transform .3s', '&:hover': {transform: 'Scale(1.5)'} }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >              
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>

        <Box sx={{overflow:'hidden', mx: 'auto', direction: 'column', alignItems: 'center', justifyContent: 'center', display: { xs: 'none', md: 'flex' }, '&:hover':{overflow:'hidden'}}}>
            {pages.map((page) => (
              <Button                
                key={page}
                onClick={handleCloseNavMenu}
                sx={{my: 2, mx: 3, color: 'white', display: 'block', transition: 'transform .3s', overflow:'hidden', '&:hover': {transform: 'Scale(1.5)', overflow:'hidden'} }}
              >
                <Link style={{color: 'white', textDecoration: 'none', }} to={`/${page}`}>
                  {page}
                </Link>
              </Button>
            ))}
          </Box>

      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;