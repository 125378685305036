export type Desire = {
    description: string,
    id: number
}

export const desireInfo: Desire[] = [
    {
        description: "grow my money",
        id: 1
    },
    {
        description: "prevent fraud",
        id: 2
    },
    {
        description: "work on my credit",
        id: 3
    },
    {
        description: "buy a home",
        id: 4
    },    
];

export const purposeInfo: Desire[] = [
    {
        description: "sleep better at night",
        id: 1
    },
    {
        description: "feel more secure about my future",
        id: 2
    },
    {
        description: "buy a car",
        id: 3
    },
    {
        description: "protect the things that I love",
        id: 4
    },
]

export const cardInfo = [
    {
        description: "that are popular",
        id: 1,
        style: 'popular'
    },
    {
        description: "for building credit",
        id: 2,
        style: 'credit'
    },
    {
        description: "with cash back",
        id: 3,
        style: 'cash'
    },
    {
        description: "with dining & entertainment rewards",
        id: 4,
        style: 'rewards'
    },
    {
        description: "with travel points",
        id: 5,
        style: 'travel'
    },
    {
        description: "for certain retailers",
        id: 6,
        style: 'retail'
    },
]